import { useState } from 'react';
import { validateForm } from '../../helpers/form';
import { useSearchParams } from 'react-router-dom';
import { authorizeUser, getUserPermissions } from '../../helpers/auth';
import { Input, InputPassword } from '../../components/Input';

const LoginWithUsername = ({ setLoginType }) => {
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const handleSubmit = e => {
    e.preventDefault();
    setError({});
    const isValid = validateForm(
      [
        {
          name: 'username',
          value: e.target.username.value,
          rules: [{ validate: 'required', message: 'Silahkan masukkan username anda!' }],
        },
        {
          name: 'password',
          value: e.target.password.value,
          rules: [{ validate: 'required', message: 'Silahkan masukkan password anda!' }],
        },
      ],
      setError
    );

    if (!isValid) {
      return;
    }

    const formBody = {
      client_id: searchParams.get('client_id'),
      redirect_uri: searchParams.get('redirect_uri'),
      remote_origin: searchParams.get('remote_origin'),
      response_type: searchParams.get('response_type'),
    };

    if (!formBody.client_id && !formBody.redirect_uri && !formBody.remote_origin && !formBody.response_type) {
      setError({ global: 'Maaf URL tidak valid' });
      return;
    }

    setLoading(true);
    getUserPermissions({ ...formBody, username: e.target.username.value, password: e.target.password.value })
      .then(res => {
        if (!res.data) {
          setError(pre => ({
            ...pre,
            global: 'User tidak memiliki akses',
          }));
          return;
        }
        authorizeUser({
          ...formBody,
          user_permission_id: res.data[0]?.user_permission_id,
          session: res.data[0]?.session,
        })
          .then(res => window.location.assign(res.data))
          .catch(err =>
            setError(pre => ({
              ...pre,
              global: err?.data?.error || 'Maaf terjadi kesalahan, mohon coba beberapa saat lagi!',
            }))
          );
      })
      .catch(() => setError(pre => ({ ...pre, global: 'Username atau password salah' })))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="login-text">Login</div>
      <div className="login-desc">
        Selamat datang, silahkan login dengan data yang Anda masukkan pada saat pendaftaran
      </div>
      <form onSubmit={handleSubmit} className="pt-3">
        <Input name="username" label="Username" error={error} setError={setError} />
        <InputPassword
          name="password"
          label="Password"
          type="password"
          error={error}
          setError={setError}
          extraLabel={<a href="/oauth/forgot-password">Lupa Password?</a>}
        />
        {error.global && <div className="alert-error mb-4">{error.global}</div>}
        <div className="mt-5">
          <button className="btn-primary" disabled={loading}>
            {loading && <div className="loader"></div>}
            Login
          </button>
        </div>
      </form>
      <div className="divider">
        <span>Atau</span>
      </div>
      <div className="mt-5">
        <button className="btn-secondary" onClick={() => setLoginType('phone')}>
          Masuk dengan nomor telepon
        </button>
      </div>
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        Tidak punya akun? <a href={`/oauth/register?${searchParams.toString()}`}>Sign Up</a>
      </div>
    </>
  );
};

export default LoginWithUsername;
