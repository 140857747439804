import { useState } from 'react';
import eye from '../assets/eye.svg';
import closeEye from '../assets/close-eye.svg';

export const Input = ({
  label,
  name,
  placeholder,
  type = 'text',
  error,
  setError,
  extraLabel,
  normalize,
  maxLength,
}) => {
  const [value, setValue] = useState('');

  const handleChange = e => {
    let newValue = normalize ? normalize(e.target.value) : e.target.value;
    setValue(newValue);
    if (setError) setError({ ...error, [name]: null });
  };

  return (
    <div className="mb-3">
      <div className="label-input">
        <label>{label}</label>
        {extraLabel}
      </div>
      <input
        className={error[name] ? 'error' : ''}
        type={type}
        placeholder={placeholder || 'Masukkan ' + label}
        name={name}
        maxLength={maxLength}
        value={value}
        onChange={handleChange}
      />
      {error[name] && <div className="text-error">{error[name]}</div>}
    </div>
  );
};

export const InputGroup = ({
  label,
  name,
  placeholder,
  type = 'text',
  error,
  maxLength,
  setError,
  extraLabel,
  normalize,
  prefix,
}) => {
  const [value, setValue] = useState('');

  const handleChange = e => {
    let newValue = normalize ? normalize(e.target.value) : e.target.value;
    setValue(newValue);
    if (setError) setError({ ...error, [name]: null });
  };

  return (
    <div className="mb-4">
      <div className="label-input">
        <label>{label}</label>
        {extraLabel}
      </div>
      <div className="input-group">
        {prefix && <div className="input-prefix">{prefix}</div>}
        <input
          className={error[name] ? 'error' : ''}
          type={type}
          placeholder={placeholder || 'Masukkan ' + label}
          name={name}
          value={value}
          maxLength={maxLength}
          onChange={handleChange}
        />
      </div>
      {error[name] && <div className="text-error">{error[name]}</div>}
    </div>
  );
};

export const InputPassword = ({ label, name, placeholder, error, setError, extraLabel, normalize }) => {
  const [value, setValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = e => {
    let newValue = normalize ? normalize(e.target.value) : e.target.value;
    setValue(newValue);
    if (setError) setError({ ...error, [name]: null });
  };

  return (
    <div className="mb-4">
      <div className="label-input">
        <label>{label}</label>
        {extraLabel}
      </div>
      <div className="input-password">
        <input
          className={error[name] ? 'error' : ''}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder || 'Masukkan ' + label}
          name={name}
          value={value}
          onChange={handleChange}
        />
        <img
          src={showPassword ? closeEye : eye}
          alt=""
          className="password-icon"
          width={16}
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
      {error[name] && <div className="text-error">{error[name]}</div>}
    </div>
  );
};
