import { useState } from 'react';
import { validateForm } from '../../helpers/form';
import { useSearchParams } from 'react-router-dom';
import { validNumber } from '../../helpers/normalize';
import { InputGroup } from '../../components/Input';

const LoginWithPhone = ({ setLoginType, setPhone }) => {
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const handleSubmit = e => {
    e.preventDefault();
    setError({});
    const isValid = validateForm(
      [
        {
          name: 'phone',
          value: e.target?.phone?.value,
          rules: [{ validate: 'required', message: 'Silahkan masukkan nomor telepon anda!' }],
        },
      ],
      setError
    );

    if (!isValid) {
      return;
    }

    const formBody = {
      client_id: searchParams.get('client_id'),
      redirect_uri: searchParams.get('redirect_uri'),
      remote_origin: searchParams.get('remote_origin'),
      response_type: searchParams.get('response_type'),
    };

    if (!formBody.client_id && !formBody.redirect_uri && !formBody.remote_origin && !formBody.response_type) {
      setError({ global: 'Maaf URL tidak valid' });
      return;
    }
    setLoginType('select-otp');
    setPhone(e.target?.phone?.value);
  };

  return (
    <>
      <div className="login-text">Login</div>
      <div className="login-desc">
        Selamat datang, silahkan login dengan data yang Anda masukkan pada saat pendaftaran
      </div>
      <form onSubmit={handleSubmit} className="pt-3">
        <InputGroup
          name="phone"
          label="Nomor Telepon"
          error={error}
          setError={setError}
          normalize={validNumber}
          maxLength={15}
          prefix="+62"
        />
        {error.global && <div className="alert-error mb-4">{error.global}</div>}
        <div className="mt-5">
          <button className="btn-primary" disabled={loading}>
            {loading && <div className="loader"></div>}
            Login
          </button>
        </div>
      </form>
      <div className="divider">
        <span>Atau</span>
      </div>
      <div className="mt-5">
        <button className="btn-secondary" onClick={() => setLoginType('username')}>
          Masuk dengan username
        </button>
      </div>
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        Tidak punya akun? <a href={`/oauth/register?${searchParams.toString()}`}>Sign Up</a>
      </div>
    </>
  );
};

export default LoginWithPhone;
