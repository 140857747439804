import './App.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './containers/Login';
import Register from './containers/Register';
import Otp from './containers/Otp';
import ForgotPassword from './containers/ForgotPassword';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/oauth/authorize" name="Login" element={<Login />} />
        <Route path="/oauth/register" name="Register" element={<Register />} />
        <Route path="/oauth/otp" name="Otp" element={<Otp />} />
        <Route path="/oauth/forgot-password" name="Forgot Password" element={<ForgotPassword />} />
        <Route path="*" element={<Navigate to="/oauth/authorize" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
