import sms from '../../assets/sms.svg';
import smsActive from '../../assets/sms-active.svg';
import chat from '../../assets/chat.svg';
import chatActive from '../../assets/chat-active.svg';
import { useState } from 'react';
import { sendOtp } from '../../helpers/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import moment from 'moment';

const cookies = new Cookies();

const SelectOtp = ({ setLoginType, phone }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('whatsapp');
  const [error, setError] = useState({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleSubmit = () => {
    console.log(value, phone);
    const formBody = {
      client_id: searchParams.get('client_id'),
      redirect_uri: searchParams.get('redirect_uri'),
      remote_origin: searchParams.get('remote_origin'),
      response_type: searchParams.get('response_type'),
    };
    setLoading(true);
    sendOtp({ ...formBody, contact: `62${phone}` })
      .then(res => {
        cookies.set('resendOtpTimes', moment().add(120, 'seconds').format());
        cookies.set('tokenOtp', res?.data?.token);
        navigate({ pathname: '/oauth/otp', search: searchParams.toString() });
      })
      .catch(() => setError(pre => ({ ...pre, global: 'Nomor telepon tidak ditemukan' })))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="login-text">Pilih OTP</div>
      <div className="login-desc">Silahkan pilih opsi di bawah untuk mengirim Kode verifikasi</div>
      <div className="mt-5">
        <label className={`radio-otp ${value === 'whatsapp' ? 'active' : ''}`}>
          <div>
            <div className="radio-wrapper">
              <input
                type="radio"
                value="whatsapp"
                defaultChecked
                name="select_otp"
                onChange={e => setValue(e.target.value)}
              />
              <span>Whatsapp</span>
            </div>
            <div className="otp-desc">Kode verifikasi akan dikirimkan ke whatsapp Anda</div>
          </div>
          <img src={value === 'whatsapp' ? chatActive : chat} alt="logo" width={40} />
        </label>
        <label className={`radio-otp ${value === 'sms' ? 'active' : ''} disabled`}>
          <div>
            <div className="radio-wrapper">
              <input type="radio" disabled value="sms" name="select_otp" onChange={e => setValue(e.target.value)} />
              <span>SMS</span>
            </div>
            <div className="otp-desc">Kode verifikasi akan dikirimkan ke whatsapp Anda</div>
          </div>
          <img src={value === 'sms' ? smsActive : sms} alt="logo" width={40} />
        </label>
      </div>
      {error.global && <div className="alert-error mb-4">{error.global}</div>}
      <div className="mt-5 pt-3 flex-gap">
        <button className="btn-secondary" onClick={() => setLoginType('phone')}>
          Kembali
        </button>
        <button className="btn-primary" disabled={loading} onClick={handleSubmit}>
          {loading && <div className="loader"></div>}
          Kirim OTP
        </button>
      </div>
    </>
  );
};

export default SelectOtp;
