import logo from '../assets/nas-text-logo.svg';
import { useState } from 'react';
import OTPInput from 'react-otp-input';
import CountdownOtp from './components/CountdownOtp';
import { authorizeUser, resendOtp, verifyOtp } from '../helpers/auth';
import { Cookies } from 'react-cookie';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

const cookies = new Cookies();

const getCurrentTime = () => {
  const resendTime = moment(cookies.get('resendOtpTimes'));
  return (resendTime.unix() - moment().unix()) * 1000;
};

const Otp = () => {
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState('');
  const [otp, setOtp] = useState('');
  const [countdownKey, setCountdownKey] = useState(0);
  const [countTime, setCountTime] = useState(getCurrentTime());
  const [searchParams] = useSearchParams();

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const token = cookies.get('tokenOtp');
    verifyOtp({ otp, token })
      .then(res => {
        if (!res.data) {
          setError(pre => ({
            ...pre,
            global: 'User tidak memiliki akses',
          }));
          return;
        }
        const cookies = new Cookies();
        cookies.set('resendOtpTimes', moment().add(0, 'seconds').format());

        const formBody = {
          client_id: searchParams.get('client_id'),
          redirect_uri: searchParams.get('redirect_uri'),
          remote_origin: searchParams.get('remote_origin'),
          response_type: searchParams.get('response_type'),
        };
        authorizeUser({
          ...formBody,
          user_permission_id: res.data[0]?.user_permission_id,
          session: res.data[0]?.session,
        })
          .then(res => window.location.assign(res.data))
          .catch(err =>
            setError(pre => ({
              ...pre,
              global: err?.data?.error || 'Maaf terjadi kesalahan, mohon coba beberapa saat lagi!',
            }))
          );
      })
      .catch(() => setError('OTP salah'))
      .finally(() => setLoading(false));
  };

  const handleChange = value => {
    setError('');
    setOtp(value);
  };

  const handleResend = () => {
    setLoadingResend(true);
    resendOtp({ phone: '', notification_type: 'whatsapp' }).finally(() => {
      setCountdownKey(pre => pre + 1);
      setLoadingResend(false);
      setCompleted(false);
      setCountTime(120000);
      cookies.set('resendOtpTimes', moment().add(120, 'seconds').format());
    });
  };

  return (
    <div className="container">
      <div className="card">
        <div className="avatar-card">
          <img src={logo} alt="logo" width={60} />
        </div>
        <div className="login-text">OTP</div>
        <div className="login-desc">Masukkan 4 digit kode yang sudah kami kirimkan</div>
        <form onSubmit={handleSubmit} style={{ paddingTop: '2rem' }}>
          <OTPInput
            value={otp}
            onChange={handleChange}
            numInputs={4}
            renderInput={props => <input {...props} className={error ? 'error' : ''} />}
            inputStyle={{ width: 40, height: 40, margin: '0 8px' }}
            containerStyle={{ justifyContent: 'center' }}
            shouldAutoFocus
          />
          {error && <div className="alert-error mb-4 mt-3 text-center">{error}</div>}
          <div style={{ marginTop: '3rem' }}>
            <button className="btn-primary" disabled={loading || otp.length < 4}>
              {loading && <div className="loader"></div>}
              Submit
            </button>
          </div>
        </form>
        <div className="mt-5">
          <button className="btn-secondary" onClick={handleResend} disabled={!completed}>
            {loadingResend && <div className="loader"></div>}
            <CountdownOtp countdownKey={countdownKey} setCompleted={setCompleted} countTime={countTime} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Otp;
