export const numberOnly = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  return onlyNums;
};

export const validNumber = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/^0+|[^\d]/g, '');
  return onlyNums;
};
