import axios from 'axios';
import { AUTH_URL } from '../config';

export const getUserPermissions = async data => {
  try {
    const res = await axios.post(`${AUTH_URL}/oauth/user-permissions`, data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const sendOtp = async data => {
  try {
    const res = await axios.post(`${AUTH_URL}/oauth/login/phone-number`, data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const resendOtp = async data => {
  try {
    const res = await axios.post(`${AUTH_URL}/oauth/login/otp/resend`, data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const verifyOtp = async data => {
  try {
    const res = await axios.post(`${AUTH_URL}/oauth/login/phone-number/otp/validate`, data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const authorizeUser = async data => {
  try {
    const res = await axios.post(`${AUTH_URL}/oauth/authorize`, data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const resetPassword = async data => {
  try {
    const res = await axios.post(`${AUTH_URL}/users/reset-password`, data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const registerAsesi = async data => {
  try {
    const res = await axios.post(`${AUTH_URL}/auth/asesi`, data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const registerAsesor = async data => {
  try {
    const res = await axios.post(`${AUTH_URL}/auth/asesor`, data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};
