export const validateForm = (values = [], setError) => {
  if (!setError) {
    return false;
  }
  let isValid = true;
  values.forEach(item => {
    item.rules.every(rule => {
      if (rule?.validate === 'required' && !item?.value) {
        isValid = false;
        setError(pre => ({ ...pre, [item?.name]: rule?.message || `Required` }));
        return false;
      }
      if (rule?.validate === 'email') {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
        if (regex.test(item?.value) === false) {
          isValid = false;
          setError(pre => ({ ...pre, [item?.name]: rule?.message || `Email is not valid` }));
        }
        return false;
      }
      return true;
    });
  });

  return isValid;
};
