import logo from '../assets/nas-text-logo.svg';
import { useState } from 'react';
import { validateForm } from '../helpers/form';
import { useSearchParams } from 'react-router-dom';
import { Input, InputGroup } from '../components/Input';
import { numberOnly, validNumber } from '../helpers/normalize';
import { registerAsesi, registerAsesor } from '../helpers/auth';

const Register = () => {
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const handleSubmit = e => {
    e.preventDefault();
    setError({});
    const isValid = validateForm(
      [
        {
          name: 'nik',
          value: e.target.nik.value,
          rules: [{ validate: 'required', message: 'Silahkan masukkan NIK anda!' }],
        },
        {
          name: 'first_name',
          value: e.target.first_name.value,
          rules: [{ validate: 'required', message: 'Silahkan masukkan nama depan anda!' }],
        },
        {
          name: 'last_name',
          value: e.target.last_name.value,
          rules: [{ validate: 'required', message: 'Silahkan masukkan nama belakang anda!' }],
        },
        {
          name: 'email',
          value: e.target.email.value,
          rules: [
            { validate: 'required', message: 'Silahkan masukkan email anda!' },
            { validate: 'email', message: 'Email tidak valid!' },
          ],
        },
        {
          name: 'phone',
          value: e.target.phone.value,
          rules: [{ validate: 'required', message: 'Silahkan masukkan nomor telepon anda!' }],
        },
      ],
      setError
    );

    if (!isValid) {
      return;
    }

    const formBody = {
      nik: e.target.nik.value,
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      email: e.target.email.value,
      phone: `62${e.target.phone.value}`,
      lsp_code: e.target.lsp_code.value,
    };
    setLoading(true);
    if (e.target.register_type.value === 'asesi') {
      registerAsesi(formBody)
        .then(res => {
          console.log(res);
        })
        .catch(() => setError(pre => ({ ...pre, global: 'Maaf terjadi kesalahan, mohon coba beberapa saat lagi' })))
        .finally(() => setLoading(false));
    } else {
      registerAsesor(formBody)
        .then(res => {
          console.log(res);
        })
        .catch(() => setError(pre => ({ ...pre, global: 'Maaf terjadi kesalahan, mohon coba beberapa saat lagi' })))
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className="container">
      <div className="card">
        <div className="avatar-card">
          <img src={logo} alt="logo" width={60} />
        </div>
        <div className="login-text">Sign Up</div>

        <form onSubmit={handleSubmit}>
          <Input name="nik" label="NIK" error={error} setError={setError} normalize={numberOnly} maxLength={16} />
          <Input name="first_name" label="Nama Depan" error={error} setError={setError} />
          <Input name="last_name" label="Nama Belakang" error={error} setError={setError} />
          <Input name="email" label="Email" error={error} setError={setError} />
          <InputGroup
            name="phone"
            label="Nomor Telepon"
            error={error}
            setError={setError}
            normalize={validNumber}
            maxLength={15}
            prefix="+62"
          />
          <Input name="lsp_code" label="Unique Code" error={error} setError={setError} />
          <div className="mb-2">Daftar Sebagai</div>
          <div className="flex-gap">
            <label className="radio-wrapper">
              <input
                type="radio"
                defaultChecked
                value="asesi"
                name="register_type"
                className="small"
                onChange={e => console.log(e.target.value)}
              />
              <span>Asesi</span>
            </label>
            <label className="radio-wrapper">
              <input
                type="radio"
                value="asesor"
                className="small"
                name="register_type"
                onChange={e => console.log(e.target.value)}
              />
              <span>Asesor</span>
            </label>
          </div>
          {error.global && <div className="alert-error mt-4">{error.global}</div>}
          <div className="mt-5">
            <button className="btn-primary" disabled={loading}>
              {loading && <div className="loader"></div>}
              Daftar
            </button>
          </div>
        </form>
        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
          Sudah punya akun? <a href={`/oauth/authorize?${searchParams.toString()}`}>Login disini</a>
        </div>
      </div>
    </div>
  );
};

export default Register;
