import logo from '../assets/nas-text-logo.svg';
import { useState } from 'react';
import { Input } from '../components/Input';
import { useNavigate } from 'react-router-dom';
import { validateForm } from '../helpers/form';
import { resetPassword } from '../helpers/auth';

const ForgotPassword = () => {
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    setError({});
    const isValid = validateForm(
      [
        {
          name: 'email',
          value: e.target?.email?.value,
          rules: [
            { validate: 'required', message: 'Silahkan masukkan email anda!' },
            { validate: 'email', message: 'Email tidak valid!' },
          ],
        },
      ],
      setError
    );

    if (!isValid) {
      return;
    }
    setLoading(true);
    resetPassword({ email: e.target?.email?.value })
      .then(res => {
        setAlertSuccess('Password berhasil dikirimkan, silahkan cek email anda dan login kembali.');
      })
      .catch(err => setError(pre => ({ ...pre, global: err?.data?.error || 'Email tidak terdaftar' })))
      .finally(() => setLoading(false));
  };

  return (
    <div className="container">
      <div className="card">
        <div className="avatar-card">
          <img src={logo} alt="logo" width={60} />
        </div>
        <div className="login-text">Lupa Password</div>
        <div className="login-desc">Silahkan masukkan email anda untuk mendapatkan password baru.</div>
        <form onSubmit={handleSubmit} className="pt-3">
          <Input name="email" label="Email" error={error} setError={setError} />
          {error.global && <div className="alert-error mb-4">{error.global}</div>}
          {alertSuccess && <div className="alert-success text-center mb-4">{alertSuccess}</div>}
          <div className="mt-5 pt-5 flex-gap">
            <button className="btn-secondary" type="button" onClick={() => navigate(-1)}>
              Kembali Login
            </button>
            <button className="btn-primary" disabled={loading}>
              {loading && <div className="loader"></div>}
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
