import logo from '../assets/nas-text-logo.svg';
import { useState } from 'react';
import LoginWithUsername from './components/LoginWithUsername';
import LoginWithPhone from './components/LoginWithPhone';
import SelectOtp from './components/SelectOtp';

const Login = () => {
  const [loginType, setLoginType] = useState('username');
  const [phone, setPhone] = useState('');

  return (
    <div className="container">
      <div className="card">
        <div className="avatar-card">
          <img src={logo} alt="logo" width={60} />
        </div>
        {loginType === 'username' && <LoginWithUsername setLoginType={setLoginType} />}
        {loginType === 'phone' && <LoginWithPhone setLoginType={setLoginType} setPhone={setPhone} />}
        {loginType === 'select-otp' && <SelectOtp setLoginType={setLoginType} phone={phone} />}
      </div>
    </div>
  );
};

export default Login;
