import { memo } from 'react';
import Countdown, { zeroPad } from 'react-countdown';

const CountdownOtp = ({ countdownKey, setCompleted, countTime }) => {
  const renderCountdown = ({ minutes, seconds, completed }) => {
    if (completed) {
      return 'Kirim ulang OTP';
    }
    return `Kirim ulang OTP (${zeroPad(minutes)}:${zeroPad(seconds)})`;
  };

  return (
    <Countdown
      date={Date.now() + countTime}
      renderer={renderCountdown}
      key={countdownKey}
      onComplete={() => setCompleted(true)}
    />
  );
};

export default memo(CountdownOtp);
